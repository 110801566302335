import { Lightning, Launch, PlatformSettings, AppData } from "@lightningjs/sdk";
import { App } from "./App.js";

import "./lib/polyfills";
import "./lib/iap";

export default function (
    appSettings: Lightning.Application.Options,
    platformSettings: PlatformSettings,
    appData: AppData
) {
    const metaTag = document.createElement("meta");
    metaTag.setAttribute("name", "viewport");
    metaTag.setAttribute("content", "initial-scale=1, width=device-width, viewport-fit=cover");
    document.querySelector("head")!.appendChild(metaTag);

    const styleTag = document.createElement("style");
    styleTag.innerHTML = `
      canvas, video {
        max-width: 100vw;
        max-height: 100vh;
      }
    `;
    document.head.appendChild(styleTag);

    return Launch(App, appSettings, platformSettings, appData);
}
