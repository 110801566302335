import { Home } from "../pages/Home";
import { Movie } from "../pages/Movie";
import { Playback } from "../pages/Playback";
import { Browse } from "../pages/Browse";
import { Search } from "../pages/Search";
import {
    deviceToken,
    getCategories,
    getFavorites,
    getMovieById,
    getMoviesByCategory,
    getNewReleases,
    getPlayback,
    getSubscription
} from "./api";
import { Settings as SettingsPage } from "../pages/Settings";
import { PLATFORMS, STORAGE_KEYS, storageSaveUser } from "./utils";
import { Favorite } from "../pages/Favorite";
import { Profile, Router, Storage } from "@lightningjs/sdk";
import { ErrorPage } from "../pages/ErrorPage";

import * as Sentry from "@sentry/browser";
import posthog from "posthog-js";
import { Boot } from "../pages/Boot";

export default {
    boot: () => {
        return new Promise<void>((resolve) => {
            (async () => {
                const query = Router.getQueryStringParams();

                const queryPlatform = query ? query.platform : undefined;

                const userAgent = navigator.userAgent.toLowerCase();

                const isSamsung = typeof window.tizen !== "undefined";
                const isLG = /(webos|web0s)/i.test(userAgent);

                const tvPlatform = isSamsung
                    ? PLATFORMS.samsung
                    : isLG
                    ? PLATFORMS.lg
                    : userAgent.indexOf("whaletv") > 0
                    ? PLATFORMS.whaletv
                    : userAgent.indexOf("philips") > 0
                    ? PLATFORMS.philips
                    : userAgent.indexOf("nettv") > 0
                    ? PLATFORMS.nettv
                    : typeof window.Hisense_GetDeviceID === "function"
                    ? PLATFORMS.hisense
                    : undefined;

                Storage.set(STORAGE_KEYS.platform, tvPlatform || (await Profile.platform()));

                Sentry.init({
                    dsn: "https://ad27022944804f0b82dafbce3ec38ac5@o599374.ingest.sentry.io/4505169061150720",
                    integrations: [new Sentry.BrowserTracing()]
                });

                posthog.init("phc_soaxt9Y7RISuu3bZ0dhNdbmWbdjEDwTRK4Wxd26rDts", {
                    api_host: "https://app.posthog.com",
                    autocapture: false
                });
                posthog.identify(await deviceToken(), { platform: Storage.get(STORAGE_KEYS.platform) });

                posthog.capture("AppStart");

                try {
                    if (queryPlatform === PLATFORMS.google) {
                        // call getPurchaseToken for google, user id is not available there.
                        // might be as well empty
                        const userId = window.Android!.getPurchaseToken();
                        storageSaveUser({
                            userId,
                            currentPeriodEnd: null
                        });

                        console.log(`GET USER ID ${userId}`);
                    }

                    const data = await getSubscription();
                    storageSaveUser(data);
                } catch (e) {
                    storageSaveUser(undefined);
                }

                resolve();
            })();
        });
    },
    root: "home",
    routes: [
        {
            path: "$",
            component: Boot
        },
        {
            path: "home",
            component: Home,
            options: {
                clearHistory: true
            },
            on: (page: any) => {
                return new Promise<void>((resolve, reject) => {
                    (async () => {
                        try {
                            page.data = await getNewReleases();

                            resolve();
                        } catch (e) {
                            reject(e);
                        }
                    })();
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"Exit"
            ],
            cache: 60
        },
        {
            path: "movie/:movieId",
            component: Movie,
            on: (page: any, { movieId }: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        page.data = await getMovieById(movieId);

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription"
            ]
        },
        {
            path: "playback/:movieId",
            component: Playback,
            on: (page: any, { movieId }: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        const data = await getPlayback(movieId);

                        posthog.capture("Playback", {
                            movie_title: data.movie?.title || ""
                        });

                        page.data = data;

                        resolve();
                    } catch (e) {
                        console.log("ERR", e);

                        const user = Storage.get(STORAGE_KEYS.user);
                        if (!user || (user && user.currentPeriodEnd && user.currentPeriodEnd < Date.now() / 1000)) {
                            page.redirect = true;

                            resolve();
                        } else {
                            reject(e);
                        }
                    }
                });
            },
            cache: 60
        },
        {
            path: "browse",
            component: Browse,
            on: (page: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        const data = await getCategories();
                        if (data[0]) {
                            const category = data[0];
                            category.movies = await getMoviesByCategory(category.categoryId);
                        }

                        page.data = data;

                        resolve();
                    } catch (e) {
                        console.log("e", e);
                        reject(e);
                    }
                });
            },
            widgets: [<Lowercase<string>>"Menu", <Lowercase<string>>"Auth", <Lowercase<string>>"Subscription"],
            cache: 60
        },
        {
            path: "favorite",
            component: Favorite,
            on: (page: any) => {
                // eslint-disable-next-line no-async-promise-executor
                return new Promise<void>(async (resolve, reject) => {
                    try {
                        page.data = await getFavorites();

                        resolve();
                    } catch (e) {
                        reject(e);
                    }
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription"
            ]
        },
        {
            path: "search",
            component: Search,
            on: () => {
                return new Promise<void>((resolve) => {
                    resolve();
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"IAPSubscription"
            ]
        },
        {
            path: "settings",
            component: SettingsPage,
            on: () => {
                return new Promise<void>((resolve) => {
                    resolve();
                });
            },
            widgets: [
                <Lowercase<string>>"Menu",
                <Lowercase<string>>"Subscription",
                <Lowercase<string>>"Auth",
                <Lowercase<string>>"IAPSubscription",
                <Lowercase<string>>"Privacy",
                <Lowercase<string>>"Terms",
                <Lowercase<string>>"Support"
            ]
        },
        {
            path: "!",
            component: ErrorPage,
            widgets: [<Lowercase<string>>"Menu"]
        }
    ]
};
